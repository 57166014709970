/* Global Variables */
:root {
  --first-color: #cc3636;
  --second-color: #f57328;
  --third-color: #367e18;
  --fourth-color: #ffe9a0;
}

/* Global Styles */
body {
  margin: 0;
  font-family: "Inter Tight", sans-serif;
}

.scroll {
  padding-bottom: 100px;
}

li {
  list-style: none;
}

i {
  padding: 5px;
}

/* Typography */
h1,
h2,
h3 {
  color: var(--third-color);
  font-family: "Rubik Dirt", cursive;
  padding-top: 30px;
}

.location-title,
.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.location-description,
.card-text {
  font-size: 1rem;
  color: #666;
}

/* Links */
a {
  text-decoration: none;
  color: var(--third-color);
}

a:hover {
  color: var(--second-color);
}

/* Layout */
section {
  margin-bottom: 3rem; /* Unified spacing for all sections */
}

.location-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.photo-gallery {
  display: flex;
  justify-content: center; /* Centers the images horizontally */
  gap: 20px; /* Horizontal spacing */
  flex-wrap: wrap; /* Allows images to wrap to a new row on smaller screens */
}

/* Adjusted photo-gallery images */
.photo-gallery img {
  width: 100%; /* Ensures images take up the full width of their container */
  max-width: 300px; /* Prevent images from getting too large */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: keeps rounded corners */
}

/* Cards and Components */
.location-card,
.card-deck .card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin-bottom: 2rem; /* Unified spacing for cards */
}

.location-card:hover,
.card-deck .card:hover {
  transform: scale(1.05);
}

.carousel-control-prev,
.carousel-control-next {
  background-color: transparent;
  border: none;
  color: grey;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  text-decoration: none;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  color: var(--second-color); /* Change to a more distinct hover color */
  text-decoration: none;
}

.btn-green {
  background-color: var(--third-color);
  color: white;
  border: none;
}

.btn-green:hover {
  background-color: darken(
    var(--third-color),
    10%
  ); /* Adjust hover color for visibility */
}

.carousel-inner img {
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
}

/* Wildlife Card */
.wildlife-card {
  max-width: 500px; /* Increase the maximum width */
  margin: 10px auto; /* Center the cards with spacing */
}

/* Media Queries */
@media (max-width: 768px) {
  .photo-gallery {
    flex-direction: column; /* Stack vertically */
    gap: 20px; /* Vertical gap between images */
    align-items: center; /* Centers the images horizontally when stacked */
  }
}

@media (min-width: 992px) {
  .card-deck {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .wildlife-card {
    flex: 1 1 40%; /* Increase card width to 40% of the container */
    margin: 20px;
  }
}

.media.container,
.about.container,
.conservation.container,
.gallery.container,
.home.container,
.links.container,
.location.container {
  padding-bottom: 50px; /* Adjust the value as needed */
}

/* Default styles for the footer */
.footer {
  font-size: 16px;
  padding: 10px 0;
}
.footer .icon {
  font-size: 1.5rem;
}
.footer-text {
  font-size: 14px;
}

/* Smaller screens: Reduce size and spacing */
@media (max-width: 768px) {
  .footer {
    font-size: 12px; /* Reduce overall font size */
    padding: 5px 0; /* Reduce vertical padding */
  }
  .footer .icon {
    font-size: 1.2rem; /* Reduce icon size */
  }
  .footer-text {
    font-size: 12px; /* Smaller text for footer text */
    display: none; /* Hide text completely */
  }
  .social-icons a {
    margin: 0 5px; /* Reduce spacing between icons */
  }
}
