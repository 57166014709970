/* Root colour variables */
:root {
  --main-accent: #367e18; /* Primary accent colour */
  --neutral-dark: #333333; /* Dark grey for text or borders */
  --neutral-light: #f5f5f5; /* Light grey for backgrounds */
  --neutral-background: #f8f9fa; /* Footer background colour */
  --neutral-white: #ffffff; /* White for highlights */
}

/* Navigation bar styles */
.navbar {
  background: var(--neutral-background); /* Set background to match footer */
  padding: 8px 20px; /* Reduce padding for a smaller navbar */
}

.navbar-brand {
  font-size: 1.0rem; /* Slightly larger font size for brand text */
  font-weight: none;
  color: var(--main-accent);
  display: flex;
  align-items: center;
  text-transform: none; /* Keep normal case */
  margin-right: 10px; /* Space between brand and home link */
  gap: 8px; /* Space between emoji and text */
  letter-spacing: 1px; /* Add slight spacing between letters */
}

.navbar-brand span {
  margin-right: 8px; /* Adjust space between emoji and brand name */
}

.navbar-brand .emoji {
  font-size: 1.6rem; /* Adjust emoji size for better balance */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='%23333333' viewBox='0 0 30 30'><path stroke='rgba(51, 51, 51, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-nav {
  margin-left: auto;
  display: flex;
  gap: 20px;
}

.nav-link {
  color: var(--neutral-dark);
  font-size: 0.95rem; /* Slightly smaller font size for links */
  text-transform: capitalize;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
}

.nav-link:hover {
  text-decoration: none;
  color: var(--main-accent);
  border-bottom: 2px solid var(--main-accent);
}

.active-link {
  border-bottom: 2px solid var(--main-accent);
  color: var(--main-accent);
}

@media (max-width: 768px) {
  .nav-item {
    text-align: center;
    padding: 8px 0; /* Smaller padding for mobile view */
  }
}
